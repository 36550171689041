import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { NavigateStateInterface } from './navigate.interfaces';
import { Computed, DataAction, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';

@Persistence()
@StateRepository()
@State({
  name: 'eng_navigate',
})
@Injectable({
  providedIn: 'any',
})
export class NavigateState extends NgxsDataRepository<NavigateStateInterface> {
  constructor() {
    super();
  }

  @Computed()
  get nextUrl(): string {
    return this.snapshot?.nextUrl ?? '';
  }

  @DataAction()
  setNextUrl(nextUrl: string): void {
    this.ctx.setState(() => ({ nextUrl }));
  }
}
